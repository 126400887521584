import {
  Sds,
  SdsSection15,
  SdsSection1Importer,
  SdsSection1Manufacturer,
  SdsSection2,
  SdsSection3,
  SdsSection3Material,
  SdsSection8,
  SdsSection8ACGIH,
  SdsSection8DNELDMEL,
  SdsSection8JSOH,
  SdsSection9,
  SdsSection9BoilingPoint,
  SdsSection9FlashPoint,
} from '@/interfaces/model/general/sds';
import { TemperatureUnitId } from '@/lib/enum';

export const NewSds: Sds = {
  id: 0,
  uuid: '',
  name: '',
  replaced_name: '',
  status_id: 0,
  timestamp: 0,
  note: '',
  admin_note: '',
  company_id: '',
  departments: [],
  created_by: '',
  created_user: null,
  assignee_users: [],
  updated_by: '',
  published_at: null,
  revised_at: null,
  supplier_confirmed_at: null,
  created_at: '',
  updated_at: '',
  assessed_at: null,
  deleted_at: null,
  sds_products: [],
  dynamic_list_result: [],
  sds_section8_list: [],
  sds_section14: {
    id: 0,
    sds_id: '',
    un_number: '',
    created_at: '',
    updated_at: '',
    deleted_at: null,
  },
  assistant_log_cross_checks: [],
  product_codes: [],
  tags: [],
  attachments: [],
};

export const NewSdsSection1Manufacturer: SdsSection1Manufacturer = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  name: '',
  address: '',
  phone: '',
  email: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection1Importer: SdsSection1Importer = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  name: '',
  address: '',
  phone: '',
  email: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection2: SdsSection2 = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  item_id: 0,
  category_id: 0,
  created_at: '',
  updated_at: '',
  deleted_at: null,
  sds_section2_hazard_list: [],
};

export const NewSdsSection3: SdsSection3 = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  composition_id: 0,
  created_at: '',
  updated_at: '',
  deleted_at: null,
  material_list: [],
};

export const NewSdsSection3Material: SdsSection3Material = {
  id: 0,
  sds_id: '',
  sds_section3_id: 0,
  material_name: '',
  concentration: '',
  encs_number: '',
  ishl_number: '',
  is_stated: false,
  concentration_inequality_sign: 0,
  concentration_min: 0,
  concentration_max: 0,
  concentration_unit: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
  cas_list: [],
};

export const NewSdsSection8: SdsSection8 = {
  id: 0,
  sds_id: '',
  skin_protection: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection8ACGIH: SdsSection8ACGIH = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  category_id: 0,
  concentration: null,
  concentration_unit: '',
  concentration_note: '',
  amount: null,
  amount_unit: '',
  amount_note: '',
  other: '',
  material_list: [],
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection8JSOH: SdsSection8JSOH = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  category_dust_id: 0,
  category_dust_respirable_id: 0,
  category_concentration_id: 0,
  concentration: null,
  concentration_unit: '',
  concentration_note: '',
  amount: null,
  amount_unit: '',
  amount_note: '',
  other: '',
  material_list: [],
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection8DNELDMEL: SdsSection8DNELDMEL = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  category_user_id: 0,
  category_term_id: 0,
  category_dust_route_id: 0,
  category_measurement_id: 0,
  concentration: null,
  concentration_unit: '',
  concentration_note: '',
  amount: null,
  amount_unit: '',
  amount_note: '',
  other: '',
  material_list: [],
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection9: SdsSection9 = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  condition: '',
  form: '',
  physical_condition: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection9FlashPoint: SdsSection9FlashPoint = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  flash_point_min: 0,
  flash_point_max: 0,
  flash_point_inequality_sign: 0,
  unit_id: TemperatureUnitId.CELSIUS.value,
  measurement_method: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection9BoilingPoint: SdsSection9BoilingPoint = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  boiling_point_min: 0,
  boiling_point_max: 0,
  boiling_point_inequality_sign: 0,
  unit_id: TemperatureUnitId.CELSIUS.value,
  measurement_method: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
};

export const NewSdsSection15: SdsSection15 = {
  id: 0,
  sds_id: '',
  sds_product_id: 0,
  applicable: false,
  text: '',
  law_id: 0,
  regulation1_id: 0,
  regulation2_id: 0,
  regulation3_id: 0,
  regulation4_id: 0,
  regulation5_id: 0,
  material_list: [],
  created_at: '',
  updated_at: '',
  deleted_at: null,
};
